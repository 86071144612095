<template>
	<BaseForm :title="title" :subtitle="subtitle">
		<div class="mt-4 h-4">
			<span class="text-red-600">
				{{ form.message }}
			</span>
		</div>

		<div class="intro-x mt-2">
			<input
				:value="form.data.email"
				name="email"
				type="email"
				placeholder="Email"
				class="
					intro-x
					login__input
					form-control
					py-3
					px-4
					border-gray-300
					block
				"
				required
				autofocus
				disabled
			/>
			<div class="h-4 mt-2">
				<span class="text-red-600 leading-4">
					{{ form.errors.email }}
				</span>
			</div>

			<input
				:value="form.data.code"
				@input="form.updateData"
				name="code"
				type="text"
				placeholder="Code"
				class="
					intro-x
					login__input
					form-control
					py-3
					px-4
					border-gray-300
					block
				"
				required
				autofocus
			/>
			<div class="h-4 mt-2">
				<span class="text-red-600 leading-4">
					{{ form.errors.code }}
				</span>
			</div>
		</div>

		<div class="mt-5 xl:mt-5 xl:grid xl:grid-cols-2 xl:gap-4">
			<BasicButton
				@click="form.submit"
				:disabled="form.progressing"
				:loading="form.progressing"
			>
				{{ $t('buttons.submit') }}
			</BasicButton>

			<BasicButton

				@click="resendCode"
				:disabled="forgotPasswordForm.progressing || timer !== 0"
				:loading="forgotPasswordForm.progressing"
				style-class="btn btn-outline-secondary py-3 px-4 w-full align-top"
			>
				{{ $t('buttons.resendCode') }} ({{ timer }})
			</BasicButton>
		</div>

	</BaseForm>
</template>

<script>
import { watch, ref, onMounted } from 'vue';
import { useI18n } from 'vue-i18n/index';
import { useStore } from 'vuex';
import BaseForm from '../BaseForm.vue';
import formHelper from '@/store/base/helper';
import BasicButton from '@/components/button/Basic.vue';

export default {
	components: {
		BaseForm,
		BasicButton
	},
	setup () {
		const { t } = useI18n();
		const title = ref(t('authForms.forgotPasswordToken.title'));
		const subtitle = ref(t('authForms.forgotPasswordToken.subtitle'));

		const store = useStore();
		const { email } = store.getters['auth/forgotPassword/data'];

		const form = ref({
			...formHelper('auth/forgotPasswordToken', {
				email: email,
				code: ''
			})
		});

		const forgotPasswordForm = ref({
			...formHelper('auth/forgotPassword', {
				email: email
			})
		});

		const timer = ref(0);

		watch(timer, (value) => {
			setTimeout(() => {
				if (timer.value > 0) {
					timer.value = timer.value - 1;
				}
			}, 1000);
		});

		const resendCode = () => {
			forgotPasswordForm.value.submit();
			timer.value = 60;
		};

		onMounted(() => {
			timer.value = 60;
		});

		return {
			title,
			subtitle,
			timer,
			form,
			resendCode,
			forgotPasswordForm
		};
	}
};
</script>
