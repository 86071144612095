<template>
	<BaseForm :title="title" :subtitle="subtitle">
		<div class="intro-x mt-8">
			<input
				v-model="form.email"
				type="email"
				placeholder="One time password"
				class="
					intro-x
					login__input
					form-control
					py-3
					px-4
					border-gray-300
					block
				"
				required
				autofocus
			/>

		</div>

		<div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
			<button
				class="
					btn btn-primary
					py-3
					px-4
					w-full
					xl:w-32
					xl:mr-3
					align-top
				"
				@click="login"
			>
				{{ $t('buttons.login') }}
			</button>
		</div>
	</BaseForm>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useI18n } from 'vue-i18n/index';
import BaseForm from '../BaseForm.vue';

export default {
	components: {
		BaseForm
	},
	setup (props, { emit }) {
		const { t } = useI18n();
		const title = ref(t('authForms.twoFactorChallenge.title'));
		const subtitle = ref(t('authForms.twoFactorChallenge.subtitle'));

		const form = ref({
			email: '',
			password: '',
			remember: false
		});

		const login = () => {
			emit('login', form.value);
		};

		const forgotPassword = () => {
			emit('forgotPassword');
		};

		return {
			title,
			subtitle,
			login,
			forgotPassword,
			form
		};
	}
};
</script>
