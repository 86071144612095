<template>
	<BaseForm :title="title" :subtitle="subtitle">
		<div class="mt-4 h-4">
			<span class="text-red-600" v-if="!form.errors">
				{{ form.message }}
			</span>
		</div>

		<div class="intro-x">
			<input
				:value="form.data.email"
				@input="form.updateData"
				name="email"
				type="email"
				placeholder="Email"
				class="
					intro-x
					login__input
					form-control
					py-3
					px-4
					border-gray-300
					block
				"
				required
				autofocus
			/>
			<div class="h-4 mt-2">
				<span class="text-red-600 leading-4">
					{{ form.errors.email }}
				</span>
			</div>
		</div>

		<div class="mt-5 xl:mt-5 xl:grid xl:grid-cols-2 xl:gap-4">
			<BasicButton
				@click="form.submit"
				:disabled="form.progressing"
				:loading="form.progressing"
			>
				{{ $t('buttons.forgotPassword') }}
			</BasicButton>
		</div>
	</BaseForm>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n/index';
import BaseForm from '../BaseForm.vue';
import formHelper from '@/store/base/helper';
import BasicButton from '@/components/button/Basic.vue';

export default {
	components: {
		BaseForm,
		BasicButton
	},
	setup () {
		const { t } = useI18n();
		const title = ref(t('authForms.forgotPassword.title'));
		const subtitle = ref(t('authForms.forgotPassword.subtitle'));

		const form = ref({
			...formHelper('auth/forgotPassword', {
				email: ''
			})
		});

		return {
			title,
			subtitle,
			form
		};
	}
};
</script>
