<template>
	<button
		:class="[
			disabled && 'opacity-50',
			styleClass,
		]"
		:disabled="disabled"
	>
		<font-awesome-icon
			v-if="loading"
			:icon="['fal', 'spinner']"
			class="text-white font-bold w-5 h-5 animate-spin"
		/>
		<slot v-if="!loading" />
	</button>
</template>

<script>
export default {
	props: {
		styleClass: {
			type: String,
			default: 'btn btn-primary py-3 px-4 w-full align-top'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		loading: {
			type: Boolean,
			default: false
		}
	}
};
</script>
