import { library, dom } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

const {
	faBars,
	faChevronDown,
	faHome,
	faSpinner
} = Icons;

const FontAwesomeComponent = {
	install (Vue) {
		library.add(faBars, faChevronDown, faHome, faSpinner);
		Vue.component('font-awesome-icon', FontAwesomeIcon);
		Vue.component('font-awesome-layers', FontAwesomeLayers);

		dom.watch();
	}
};

export default FontAwesomeComponent;
