<template>
	<!-- BEGIN: Login Form -->
	<div class="form-container px-6 lx:px-0">
		<div class="form-content w-full md:w-1/2  xl:min-w-1/2">
			<!-- BEGIN: Logo -->
			<div class="logo-container">
				<img
					src="@/assets/images/custom/brand-logo-colored.png"
					class="logo"
				>
			</div>
			<!-- END: Logo -->

			<h2 class="form-title">
				{{ title }}
			</h2>

			<div class="form-subtitle">
				{{ subtitle }}
			</div>

			<!-- child component -->
			<slot />
		</div>
	</div>
</template>

<script>
import '@/assets/scss/_auth-forms.scss';
export default {
	name: 'AuthBaseForm',
	props: {
		title: {
			type: String,
			required: true
		},
		subtitle: {
			type: String,
			required: true
		}
	}
};
</script>
