<template>
	<BaseForm :title="title" :subtitle="subtitle">
		<div class="mt-4 h-4">
			<span class="text-red-600" v-if="!form.errors">
				{{ form.message }}
			</span>
		</div>

		<div class="mt-4 space-y-4">
			<div>
				<PasswordMeter
					name="new_password"
					:passwordValue="form.data.new_password"
					@on-change="form.updateData"
				/>

				<div class="mt-1">
					<span class="text-red-600 text-sm leading-4">
						{{ form.errors.new_password }}
					</span>
				</div>
			</div>

			<div>
				<input
					:value="form.data.new_password_confirmation"
					@input="form.updateData"
					name="new_password_confirmation"
					type="password"
					placeholder="New Password"
					class="
						intro-x
						login__input
						form-control
						py-3
						px-4
						border-gray-300
						block
					"
					required
					autocomplete="new-password"
				/>
				<div class="h-4 mt-1">
					<span class="text-red-600 text-sm leading-4">
						{{ form.errors.new_password_confirmation }}
					</span>
				</div>
			</div>
		</div>

		<div
			class="
				intro-x
				mt-2
				xl:mt-8
				grid
				xl:grid-cols-2
				xl:gap-4
				grid-cols-1
			"
		>
			<BasicButton
				@click="form.submit"
				:disabled="form.progressing"
				:loading="form.progressing"
			>
				{{ $t('buttons.resetPassword') }}
			</BasicButton>
		</div>
	</BaseForm>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n/index';
import BaseForm from '../BaseForm.vue';
import formHelper from '@/store/base/helper';
import BasicButton from '@/components/button/Basic.vue';
import PasswordMeter from '@/components/password-meter/';

export default {
	components: {
		BaseForm,
		BasicButton,
		PasswordMeter
	},
	setup () {
		const { t } = useI18n();
		const title = ref(t('authForms.resetPassword.title'));
		const subtitle = ref(t('authForms.resetPassword.subtitle'));

		const form = ref({
			...formHelper('auth/resetPassword', {
				new_password: '',
				new_password_confirmation: ''
			})
		});

		return {
			title,
			subtitle,
			form
		};
	}
};
</script>
