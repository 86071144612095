<template>
	<TextField
		v-model="password"
		:styleClass="{
			label: 'form-label',
			textField: `
				form-control border-${color}
				intro-x
				login__input
				form-control
				py-3
				px-4
				border-gray-300
				block
			`,
		}"
		:name="name"
		type="password"
		placeholder="New Password"
		required
		autocomplete="new-password"
	/>
	<div class="w-full grid grid-cols-10 gap-4 h-1 mt-3">
		<div
			class="col-span-2 h-full rounded"
			v-for="item in [0, 1, 2, 3, 4]"
			:key="`password-meter-${item}`"
			:class="[item <= score ? `bg-${color}` : 'bg-gray-600']"
		></div>
	</div>

	<div class="w-full mt-2 text-xs">
		<span :class="`text-${color} font-bold`">
			{{ message }}
		</span>
		<span class="text-gray-700 ml-2" v-if="feedback.warning">
			* {{ feedback.warning }}
		</span>
	</div>
</template>

<script>
import zxcvbn from 'zxcvbn';
import { ref, watch } from 'vue';
import { TextField } from '@success-apps/sa-vue-tailwind';

export default {
	props: {
		passwordValue: {
			type: String,
			required: true
		},
		name: {
			type: String,
			required: true
		}
	},
	components: {
		TextField
	},
	emits: ['on-change'],
	setup (props, { emit }) {
		const password = ref('');

		const color = ref('gray-600');
		const feedback = ref({});
		const message = ref('No Password');
		const score = ref(0);

		const onChange = () => {
			const meter = zxcvbn(password.value);
			score.value = meter.score;

			switch (score.value) {
				case 1:
					color.value = 'red-600';
					message.value = 'Weak';
					break;
				case 2:
					color.value = 'yellow-400';
					message.value = 'Medium';
					break;
				case 3:
					color.value = 'yellow-300';
					message.value = 'Strong';
					break;
				case 4:
					color.value = 'green-500';
					message.value = 'Very strong';
					break;
				default:
					color.value = 'red-600';
					message.value = 'Very weak';
			}

			feedback.value = meter.feedback;

			if (!password.value) {
				color.value = 'gray-600';
				message.value = 'No Password';
			}

			emit('on-change', {
				target: {
					name: props.name,
					value: password.value
				}
			});
		};

		watch(password, onChange);

		return {
			password,
			feedback,
			message,
			color,
			score
		};
	}
};
</script>
