<template>
	<BaseForm :title="title" :subtitle="subtitle">
		<div class="mt-4 h-4">
			<span class="text-red-600" v-if="!form.errors">
				{{ form.message }}
			</span>
		</div>

		<div class="mt-4">
			<input
				:value="form.data.email"
				@input="form.updateData"
				name="email"
				type="email"
				placeholder="Email"
				class="
					intro-x
					login__input
					form-control
					py-3
					px-4
					border-gray-300
					block
				"
				required
				autofocus
			/>
			<div class="h-4 mt-1">
				<span class="text-red-600 text-sm leading-4">
					{{ form.errors.email }}
				</span>
			</div>

			<input
				:value="form.data.password"
				@input="onPasswordChange"
				name="password"
				type="password"
				placeholder="Password"
				class="
					intro-x
					login__input
					form-control
					py-3
					px-4
					border-gray-300
					block
					mt-4
				"
			/>
			<div class="h-4 mt-1">
				<span class="text-red-600 text-sm leading-4">
					{{ form.errors.password }}
				</span>
			</div>
		</div>
		<div
			class="
				intro-x
				flex
				text-gray-700
				dark:text-gray-600
				text-xs
				sm:text-sm
				mt-4
			"
		>
			<div class="flex items-center mr-auto">
				<input
					:value="form.data.remember"
					@input="form.updateData"
					name="remember"
					type="checkbox"
					class="form-check-input border mr-2"
				/>
				<label class="cursor-pointer select-none" for="remember-me"
					>{{ $t('buttons.rememberMe') }}</label
				>
			</div>

			<button @click="forgotPassword">{{ $t('buttons.forgotPassword') }}?</button>
		</div>

		<div class="mt-5 xl:mt-5 xl:grid xl:grid-cols-2 xl:gap-4">
			<BasicButton
				@click="form.submit"
				:disabled="form.progressing"
				:loading="form.progressing"
			>
				{{ $t('buttons.login') }}
			</BasicButton>
		</div>

		<div
			class="
				intro-x
				mt-10
				xl:mt-24
				text-gray-700
				dark:text-gray-600
				text-center
				xl:text-left
			"
		>
			By signin up, you agree to our <br />
			<a class="text-theme-1 dark:text-theme-10" href="">
				Terms and Conditions
			</a>
			&
			<a class="text-theme-1 dark:text-theme-10" href="">
				Privacy Policy
			</a>
		</div>
	</BaseForm>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n/index';
import zxcvbn from 'zxcvbn';
import { useRouter } from 'vue-router';
import BaseForm from '../BaseForm.vue';
import BasicButton from '@/components/button/Basic.vue';
import formHelper from '@/store/base/helper';

export default {
	components: {
		BaseForm,
		BasicButton
	},
	setup () {
		const { t } = useI18n();

		const title = ref(t('authForms.login.title'));
		const subtitle = ref(t('authForms.login.subtitle'));

		const form = ref({
			...formHelper('auth/login', {
				email: '',
				password: '',
				remember: false
			})
		});

		const router = useRouter();
		const forgotPassword = () => {
			router.push({ name: 'forgot-password' });
		};

		const onPasswordChange = (event) => {
			const { value } = event.target;

			const passwordMeter = zxcvbn(value);
			console.log(passwordMeter);
		};

		return {
			title,
			subtitle,
			forgotPassword,
			form,
			onPasswordChange
		};
	}
};
</script>
